<template>
  <div>
    <!-- <div class="text-center mb-2">
      <el-radio-group v-model="field.is_global_variable" >
        <el-radio-button :label="false">Custom Field</el-radio-button>
        <el-radio-button :label="true">Global Variable</el-radio-button>
      </el-radio-group>
    </div> -->

    <el-form label-position="right" v-if="!field.is_global_variable">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <el-row :gutter="10" >
            <el-col :span="24">
                <label>QR Generation From </label>
            </el-col>
            <el-col :span="24">
                <div class="form-group">
                    <el-radio v-model="field.validations.qrType" label="From Field">Field</el-radio>
                    <el-radio v-model="field.validations.qrType" label="Record Id">Record Id</el-radio>
                    <el-radio v-model="field.validations.qrType" label="URL">URL </el-radio>
                </div>
            </el-col>
        </el-row>
        </el-col>
        <el-col :span="12" v-if="field.validations.qrType==='From Field'">
          <!-- <field-attributes :field="field" /> -->
          <div class="form-group">
            <el-form-item label="QR Data field">
              <el-select
                class="input_type"
                v-model="field.validations.qr_data_field"
              >
                <el-option
                  v-for="(option, index) of fieldsData"
                  :key="index"
                  :value="option.key"
                  :label="option.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
    </el-form>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name:"templates-formComponents-SingleLineText",
  components: {
     "TitleAndDescription":()=>import("./TitleAndDescription"),
    // "IsFieldRequired":()=>import("./IsFieldRequired"),
    "FieldFilledBy":()=>import("./FieldFilledBy"),
    "Placeholder":()=>import("./Placeholder"),
    // "FieldAttributes":()=>import("./FieldAttributes.vue")
  },
  
  props: ["field","fieldsData"],
  computed: {
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    allGlobalVariables() {
      return this.getAllGlobalVariables
        ? this.getAllGlobalVariables.data || []
        : [];
    },
    getFilledByOptions() {
      if (
        this.field.input_type == "SINGLE_LINE_TEXT" ||
        this.field.input_type == "PASSWORD" ||
        this.field.input_type == "EMAIL" ||
        this.field.input_type == "MASKED" ||
        this.field.input_type == "HYPER_LINK"
      ) {
        return [
          { name: "PASSWORD", value: "PASSWORD" },
          { name: "EMAIL", value: "EMAIL" },
          { name: "TEXT", value: "SINGLE_LINE_TEXT" },
          { name: "MASKED", value: "MASKED" },
          { name: "HYPER LINK", value: "HYPER_LINK" }
        ];
      }
      if (
        this.field.input_type == "NUMBER" ||
        this.field.input_type == "PHONE_COUNTRY_CODE" ||
        // this.field.input_type == "FAX" ||
        this.field.input_type == "CURRENCY"
      ) {
        return [
          { name: "NUMBER", value: "NUMBER" },
          { name: "PHONE", value: "PHONE_COUNTRY_CODE" },
          { name: "CURRENCY", value: "CURRENCY" }
          // { name: "FAX", value: "FAX" },
        ];
      }
      if (
        this.field.input_type == "DATE" ||
        this.field.input_type == "TIME" ||
        this.field.input_type == "DATE_TIME" ||
        this.field.input_type == "DATE_TIME_RANGE" ||
        this.field.input_type == "DATE_RANGE"
      ) {
        return [
          { name: "DATE", value: "DATE" },
          { name: "TIME", value: "TIME" },
          { name: "DATE TIME", value: "DATE_TIME" },
          { name: "DATE TIME RANGE", value: "DATE_TIME_RANGE" },
          { name: "DATE RANGE", value: "DATE_RANGE" }
        ];
      }

      if (
        this.field.input_type == "MULTI_LINE_TEXT" ||
        this.field.input_type == "RICH_TEXT"
      ) {
        return [
          { name: "RICH TEXT", value: "RICH_TEXT" },
          { name: "PASSWORD", value: "PASSWORD" },
          { name: "EMAIL", value: "EMAIL" },
          { name: "TEXT", value: "SINGLE_LINE_TEXT" },
          { name: "NUMBER", value: "NUMBER" },
          { name: "CURRENCY", value: "CURRENCY" },
          { name: "MULTI LINE TEXT", value: "MULTI_LINE_TEXT" }
        ];
      }
      if (
        this.field.input_type == "MULTI_SELECT" ||
        this.field.input_type == "SELECT"
      ) {
        return [
          { name: "SELECT", value: "SELECT" },
          { name: "MULTI SELECT", value: "MULTI_SELECT" }
        ];
      }
      return this.fieldFilledByOptions;
    }
  },
  mounted() {
    // this.fetchGlobalVaribales();
  },
  data() {
    return {};
  },
  methods: {
    async fetchGlobalVaribales() {
     
      let params = {
        get_all: true,
        input_type: this.field.input_type
      };

      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariables",
        params
      );
    },
    setGlobalVariable() {
      let globalVariable = this.allGlobalVariables.find(
        x => x._id == this.field.global_variable_id
      );
      this.field.label = globalVariable.label;
      this.field.description = globalVariable.description;
      this.field.options = globalVariable.options;
    }
  }
};
</script>

<style lang="scss">
</style>